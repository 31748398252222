import React from 'react';
import './App.css';
import './index.css';
import {Layout, Menu, PageHeader, Divider, Row, Col, Tabs} from 'antd';
import TableContainer from "./TableContainer";

const { Header, Content, Footer} = Layout;
const { TabPane } = Tabs;

function App() {
  return (
    <Layout className="layout" style={{height:"100vh"}}>
    <Header>
      <PageHeader style={{width:'50%',float:'left',color:'white',fontSize:'1.4em',fontWeight:'800',padding:'0 100px',height:'100%'}}>OMABot</PageHeader>
      <Menu theme="dark" mode="horizontal" style={{width:'50%',float:'right'}}>
        <Menu.Item style={{color:'white',float:'right',fontWeight:'600'}} key="1">Login</Menu.Item> 
      </Menu>
    </Header>
    <Content className="site-layout-content">  
      <Row>
        <Col span={22} offset={1}>
         <Tabs defaultActiveKey="1">
          <TabPane tab="Parent" key="1">
           <TableContainer host="192.168.1.167:32081" creds="tiosalva:tiosalva.11"/>   
          </TabPane>
          <TabPane tab="Child(1)" key="2">
            <TableContainer host="192.168.1.167:32181" creds="tiosalva:tiosalva.child.11"/>   
          </TabPane>
          <TabPane tab="Child(2)" key="3">
            <TableContainer host="192.168.1.167:32281" creds="tiosalva:tiosalva.child.11"/>   
          </TabPane>
          </Tabs>
          <Divider/>
        </Col>
        </Row>
    </Content>
    <Footer style={{clear: 'both' ,  textAlign: 'right',height: '5%',margin:'0',padding:'5px 30px 0px 0px',backgroundColor:'white' }}>OMABot ©2020 Created by MIA Crew Team</Footer>
  </Layout>
  );
}

export default App;
