/* eslint-disable react/jsx-no-comment-textnodes */
import React,{Component} from 'react';
import {Table} from 'antd';


export default class TableContainer extends Component {
   
    state = {
      history:[],  
      history_columns:[{
        title: 'Idx',
        dataIndex: 'trade_id'
      },
      {
        title: 'Pair',
        dataIndex: 'pair',
        sorter: (a, b) => { return a.pair.localeCompare(b.pair)}
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        sorter: (a, b) => { return a.amount-b.amount},
        render: amount => <div style={{textAlign:"center"}}>{Number(amount).toFixed(2)}</div>
      },
      {
        title: 'Stake',
        dataIndex: 'stake_amount',
        render: stake_amount => Number(stake_amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
      },
      {
        title: 'Open',
        dataIndex: 'open_date',
        sorter: (a, b) => new Date(a.open_date).toLocaleDateString('en-us') - new Date(b.opendate).toLocaleDateString('en-us')
     },
     {
      title: 'Closed',
      dataIndex: 'close_date'
     },
     { 
       title: 'Sold',
        dataIndex: 'sell_reason'
     },
     {
      title: 'Profit',
      dataIndex: 'close_profit',
      sorter: (a, b) => { return a.close_profit-b.close_profit}
     },
     {
      title: 'Abs. Profit',
      dataIndex: 'close_profit_abs',
      sorter: (a, b) => { return a.close_profit_abs-b.close_profit_abs}
     },
     {
      title: 'Active',
      dataIndex: 'is_open',
      sorter: (a, b) => { return a.is_open-b.is_open},
      render: is_open => String(is_open)
     }
    ]
    }
    
    render (){
      return(
      
            <Table dataSource={this.state.history} style={{marginLeft:'auto',marginRight:'auto'}} columns={this.state.history_columns} size="default" pagination={false} scroll={{y: 420}} tableLayout="auto"/> 
      
      )
    }

    componentDidMount() {
        fetch(`http://${this.props.host}/api/v1/tradinghistory`,{
          headers: new Headers({
              'Authorization': 'Basic '+ btoa(`${this.props.creds}`)
          })
      }).then(res => res.json())
        .then((data) => {
            this.setState({ history: data })
            console.log (this.state.history)
        })
        .catch(console.log)
      }
  }
  